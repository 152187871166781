export default {
  apiUrl: process.env.REACT_APP_API_URL
};
const siteConfig = {
  siteName: 'PREMIO ANTP',
  siteIcon: 'ion-flash',
  footerText: 'ANTP ©2019'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'spanish';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: 'antp.auth0.com',
  clientID: 'T2B7ammbIgpP8aoapM8fMgHpWU541WG1',
  allowedConnections: ['antp-premio'],
  rememberLastLogin: true,
  language: 'es',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK,
      responseType: 'token id_token' 
    },
    languageDictionary: {
      title: 'PREMIO ANTP',
      emailInputPlaceholder: 'RFC',
      passwordInputPlaceholder: 'password'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Entrar',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: ''
};
const googleConfig = {
  apiKey: '' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
const youtubeSearchApi = '';
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};
