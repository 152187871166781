import asyncComponent from '../helpers/AsyncFunc';

const routes = [
  {
    path: 'operadores',
    component: asyncComponent(() => import('./containers/operadores'))
  },
  {
    path: 'operadores-list',
    component: asyncComponent(() => import('./containers/operadoresList'))
  },
  {
    path: 'operadores-list-admin',
    component: asyncComponent(() => import('./containers/operadoresListAdmin'))
  },
  {
    path: 'agregar-operador',
    component: asyncComponent(() => import('./containers/agregarOperador'))
  },
  {
    path: 'operadores-buscar',
    component: asyncComponent(() => import('./containers/operadoresbuscar'))
  },
  {
    path: 'operadores-examenes-premio',
    component: asyncComponent(() => import('./containers/operadoresExamenesPremio'))
  },
  {
    path: 'operadores-examenes-premio/:id',
    component: asyncComponent(() => import('./containers/operadoresExamenesPreguntas'))
  },
  {
    path: 'operadores-examenes-premio/respuestas/:id',
    component: asyncComponent(() => import('./containers/operadoresExamenesRespuestas'))
  },
  {
    path: 'operadores-examenes-premio/estadisticas/respuestas/:id',
    component: asyncComponent(() => import('./containers/operadoresExamenesEstadisticasRespuestas'))
  },
  {
    path: 'operadores-examenes-premio/operadores/:id',
    component: asyncComponent(() => import('./containers/operadoresExamenesOperadores'))
  },
  {
    path: 'operadores-examenes-premio-all',
    component: asyncComponent(() => import('./containers/operadoresExamenesOperadoresAll'))
  },
  {
    path: 'group-preguntas-all',
    component: asyncComponent(() => import('./containers/groupPreguntas'))
  },
  {
    path: 'operadores-resultados',
    component: asyncComponent(() => import('./containers/operadoresExamenesOperadoresAllResultados'))
  },
  {
    path: 'estadisticas-all',
    component: asyncComponent(() => import('./containers/operadoresExamenesEstadisticasAll'))
  },
];
export default routes;
