const options = [
  {
    key: 'agregar-operador',
    label: 'Agregar Operador',
    leftIcon: 'ion-people'
  },
  {
    key: 'operadores-list-admin',
    label: 'Listado de conductores',
    leftIcon: 'ion-people'
  },
  {
    key: 'operadores-buscar',
    label: 'Buscar conductores',
    leftIcon: 'ion-people'
  },
  {
    key: 'operadores-examenes-premio',
    label: 'Exámenes Premio',
    leftIcon: 'ion-people'
  }
];
export default options;
