import { EventEmitter } from 'events';
import Auth0Lock from 'auth0-lock';
import history from './history';
import { Auth0Config } from '../../settings';
import { notification } from '../../components';

import Settings from "../../settings";
import axios from "axios";
import { getToken } from "../../helpers/utility";

class Auth0Helper extends EventEmitter {
  isValid = Auth0Config.clientID && Auth0Config.domain;
  lock = this.isValid
    ? new Auth0Lock(
        Auth0Config.clientID,
        Auth0Config.domain,
        Auth0Config.options
      )
    : null;
  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.getUserInfo2 = this.getUserInfo2.bind(this);
  }
  login() {
    if (!this.lock) {
      return;
    }
    this.lock.show();
    this.handleAuthentication();
  }
  handleAuthentication(props) {
    
    // Add callback Lock's `authenticated` event
    this.lock.on('authenticated', this.setSession);
    // Add callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', error =>
      notification('error', 'Wrong mail or password')
    );
  }

  getUserInfo2() {
    console.log('test');
    this.lock.getUserInfo(localStorage.getItem('access_token'), function(error, profile) {
      console.log(profile);
    });
  }

  async getUserInfo() {
    const idToken = getToken().get("idToken");
    try {
      const {data} = await axios.get(
        `${Settings.apiUrl}/me`,
        {
          headers: { Authorization: `Bearer ${idToken}` }
        }
      );
      return data;
    } catch(e) {
      notification(
        "error",
        "Ocurrio un error intentalo nuevamente."
      );
    }
  }

  setSession(authResult) {
    console.log(authResult)
    // Set the time that the access token will expire at

    this.getUserInfo(authResult.accessToken, function(error, profile) {
      console.log(profile);
    });

    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the dashboard route
    history.replace('/dashboard');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    return (
      new Date().getTime() < JSON.parse(localStorage.getItem('expires_at'))
    );
  }
}
export default new Auth0Helper();
