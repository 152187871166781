const options = [
  /*{
    key: 'operadores',
    label: 'Registra a tu conductor',
    leftIcon: 'ion-person-add'
  },*/
  {
    key: '',
    label: 'Agregar Operador',
    leftIcon: 'ion-people'
  },
  {
    key: 'operadores-list',
    label: 'Listado de conductores',
    leftIcon: 'ion-people'
  },
  {
    key: 'operadores-resultados',
    label: 'Resultados',
    leftIcon: 'ion-people'
  }
];
export default options;
